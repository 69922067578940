<template>
<!--Button-->
<body >
  <div id="wrapper">
	<div id="bg"></div>
			<div id="overlay"></div>
			<div id="main">
				<!-- Header -->
					<header id="header">

						<h1><img class="logo" src="../src/images/YOSSNewLogo.png"/></h1>
						<p>Barbier</p>
						<p>Place : <a href="https://www.google.com/maps/search/Maison+Beaut%C3%A9+par+Pablo+Cutz/@45.6709841,-73.930993,17z/data=!3m1!4b1?entry=ttu&g_ep=EgoyMDI0MDkyNS4wIKXMDSoASAFQAw%3D%3D">Maison Beauté par Pablo Cutz</a></p>

						<nav>
							<ul>
								<li><a href="https://www.instagram.com/yoss.le.barbier/" class="icon brands fa-instagram"><span class="label">Instagram</span></a></li>
								
								<li><a href="https://www.facebook.com/YossLeBarbier/" class="icon brands fa-facebook-f"><span class="label">Facebook</span></a></li>
								
								<li> <a href="https://yosslebarbier.resurva.com/book" class="icon far fa-calendar-alt"><span class="label">Booking</span></a></li>
							
								<li> <a href="tel:4384027728" class="fas fa-phone"><span class="label">Téléphone</span></a></li>

							</ul>
						</nav>

						<p>Instagram &nbsp;&bull;&nbsp;FaceBook &nbsp;&bull;&nbsp; Rendez-Vous &nbsp;&bull;&nbsp; Téléphone</p>

            <p>Adresse : <a href="https://www.google.com/maps/dir//11355+Mnt+Sainte-Marianne,+Mirabel,+QC+J7J+2B2/@45.6707586,-73.9339279,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4cc929f149295083:0x3da155b686c7ee88!2m2!1d-73.928778!2d45.6707587?entry=ttu&g_ep=EgoyMDI0MDkyNS4wIKXMDSoASAFQAw%3D%3D">11355 Mnt Sainte-Marianne, Mirabel, QC, J7J 2B2</a></p>
					</header>

			</div>
	</div>

<div id="761923527">
   
</div>

</body>


  
</template>


 <script type="text/javascript">
        try {
            window._mNHandle.queue.push(function (){
                window._mNDetails.loadTag("761923527", "728x90", "761923527");
            });
        }
        catch (error){
			// eslint-disable-next-line 
		}
    </script>